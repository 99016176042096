import {initializeApp} from 'firebase/app';
import {connectFirestoreEmulator, getFirestore} from 'firebase/firestore';
import {getAnalytics} from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyCvlJUzszbsgf-D3ObXHggDnB4euO4AKLI',
  authDomain: 'mailbandit-1d9cc.firebaseapp.com',
  databaseURL: 'https://mailbandit-1d9cc-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'mailbandit-1d9cc',
  storageBucket: 'mailbandit-1d9cc.appspot.com',
  messagingSenderId: '359639967150',
  appId: '1:359639967150:web:01369499cc3637933c943f',
  measurementId: 'G-L3JZ0D6GK7',
};

export const firebaseApp = initializeApp(firebaseConfig);
export const firestore = getFirestore(firebaseApp);
export const analytics = getAnalytics(firebaseApp);

export const metricsInfoServiceURL =
  process.env.NODE_ENV !== 'production'
    ? 'http://127.0.0.1:5001/mailbandit-1d9cc/us-central1/metrics_info_service'
    : 'https://metrics-info-service-6wnqxu53tq-uc.a.run.app/';
  
export const templatesInfoServiceURL =
  process.env.NODE_ENV !== 'production'
    ? 'http://127.0.0.1:5001/mailbandit-1d9cc/us-central1/template_info_service'
    : 'https://template-info-service-6wnqxu53tq-uc.a.run.app';

if (process.env.NODE_ENV !== 'production') {
  connectFirestoreEmulator(firestore, '127.0.0.1', 8080);
}
