export const Models = Object.freeze({
  TSContinuous: 'TSContinuous',
  TSBinary: 'TSBinary',
});

export const metric_mapping = {
  placed_order_API: {
    model: Models.TSContinuous,
    klaviyo_attributes: {
      name: 'Placed Order',
      integration: {
        object: 'integration',
        key: 'api',
        name: 'API',
        category: 'API',
      },
    },
    measurement: 'sum_value',
    labels: {
      success_ylabel: `Revenue/${localStorage.getItem('currency') || '$'}`,
      allocation_ylabel: 'Allocation %',
      table_header: 'Tracked Revenue Per Email',
      statsCards: [
        'Emails Sent',
        'Revenue Increase With MailBandit per Email',
        'Additional Revenue With MailBandit',
        'Total Revenue',
      ],
    },
  },
  placed_order_ecommerce: {
    model: Models.TSContinuous,
    klaviyo_attributes: {
      name: 'Placed Order',
      integration: {
        object: 'integration',
        key: 'shopify',
        name: 'Shopify',
        category: 'eCommerce',
      },
    },
    measurement: 'sum_value',
    labels: {
      success_ylabel: `Revenue/${localStorage.getItem('currency') || '$'}`,
      allocation_ylabel: 'Allocation %',
      table_header: 'Tracked Revenue Per Email',
      statsCards: [
        'Emails Sent',
        'Revenue Increase With MailBandit per Email',
        'Additional Revenue With MailBandit',
        'Total Revenue',
      ],
    },
  },
  open_rate: {
    model: Models.TSBinary,
    klaviyo_attributes: {
      name: 'Opened Email',
      integration: {
        object: 'integration',
        key: 'klaviyo',
        name: 'Klaviyo',
        category: 'Internal',
      },
    },
    measurement: 'unique',
    labels: {
      success_ylabel: 'Open Rate %',
      allocation_ylabel: 'Allocation %',
      table_header: 'Open Rate',
      statsCards: [
        'Emails Sent',
        'Open Rate Increase With MailBandit',
        'Additional Opens With MailBandit',
        'Total Opens',
      ],
    },
  },
  click_rate: {
    model: Models.TSBinary,
    klaviyo_attributes: {
      name: 'Clicked Email',
      integration: {
        object: 'integration',
        key: 'klaviyo',
        name: 'Klaviyo',
        category: 'Internal',
      },
    },
    measurement: 'unique',
    labels: {
      success_ylabel: 'Click Rate %',
      allocation_ylabel: 'Allocation %',
      table_header: 'Click Rate',
      statsCards: [
        'Emails Sent',
        'Click Rate Increase With MailBandit',
        'Additional Clicks With MailBandit',
        'Total Clicks',
      ],
    },
  },
};
