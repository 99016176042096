import {doc, getDoc} from 'firebase/firestore';
import {firestore} from 'configuration/firebase/Firebase';
import {BinaryMetric, DailyBinaryMetric} from 'helpers/objects/BinaryMetric';
import {ContinuousMetric, DailyContinuousMetric} from '../../objects/ContinuousMetric';

const formatDatePart = part => (part < 10 ? `0${part}` : `${part}`);

export const getDailyBinaryMetric = async (experimentId, variantId, date) => {
  const twoDigitDay = formatDatePart(date.getDate());
  const twoDigitMonth = formatDatePart(date.getMonth() + 1);
  const year = date.getFullYear().toString();
  const metricRef = doc(
    firestore,
    'experiments',
    experimentId,
    'variants',
    variantId,
    'metrics',
    year,
    twoDigitMonth,
    twoDigitDay
  );

  try {
    const metricSnap = await getDoc(metricRef);
    if (metricSnap.exists()) {
      const metric = metricSnap.data();
      return new DailyBinaryMetric(
        metric.success_count,
        metric.failure_count,
        experimentId,
        variantId,
        date
      );
    } else {
      throw new Error('No such metric: ' + metricRef.path);
    }
  } catch (error) {
    console.error(error);
    throw new Error('Unable to get metric: ' + metricRef.path);
  }
};

export const getOverallBinaryMetric = async (experimentId, variantId) => {
  const metricRef = doc(
    firestore,
    'experiments',
    experimentId,
    'variants',
    variantId,
    'metrics',
    'overall_metrics'
  );

  try {
    const metricSnap = await getDoc(metricRef);
    if (metricSnap.exists()) {
      const metric = metricSnap.data();
      return new BinaryMetric(metric.success_count, metric.failure_count, experimentId, variantId);
    } else {
      throw new Error('No such metric: ' + metricRef.path);
    }
  } catch (error) {
    console.error(error);
    throw new Error('Unable to get metric: ' + metricRef.path);
  }
};

export const getDailyContinuousMetric = async (experimentId, variantId, date) => {
  const twoDigitDay = formatDatePart(date.getDate());
  const twoDigitMonth = formatDatePart(date.getMonth() + 1);
  const year = date.getFullYear().toString();
  const metricRef = doc(
    firestore,
    'experiments',
    experimentId,
    'variants',
    variantId,
    'metrics',
    year,
    twoDigitMonth,
    twoDigitDay
  );

  try {
    const metricSnap = await getDoc(metricRef);
    if (metricSnap.exists()) {
      const metric = metricSnap.data();
      return new DailyContinuousMetric(
        metric.mean,
        metric.variance,
        metric.count,
        experimentId,
        variantId,
        date
      );
    } else {
      throw new Error('No such metric: ' + metricRef.path);
    }
  } catch (error) {
    console.error(error);
    throw new Error('Unable to get metric: ' + metricRef.path);
  }
};

export const getOverallContinuousMetric = async (experimentId, variantId) => {
  const metricRef = doc(
    firestore,
    'experiments',
    experimentId,
    'variants',
    variantId,
    'metrics',
    'overall_metrics'
  );

  try {
    const metricSnap = await getDoc(metricRef);
    if (metricSnap.exists()) {
      const metric = metricSnap.data();
      return new ContinuousMetric(
        metric.mean,
        metric.variance,
        metric.count,
        experimentId,
        variantId
      );
    } else {
      throw new Error('No such metric: ' + metricRef.path);
    }
  } catch (error) {
    console.error(error);
    throw new Error('Unable to get metric: ' + metricRef.path);
  }
};
