import React from 'react';
import {InputField} from './InputField';
import tw from 'twin.macro';
import {OptionsButton, RedButton} from 'components/items/Buttons';
import InfoTooltip from '../../helpers/tour/InfoTooltip';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
  TableWrapper,
} from '../items/Table';

const ButtonsRowContainer = tw.div`w-full flex justify-center space-x-4 mt-6`;
const InlineContainer = tw.div`inline-flex items-center`;

export const MultiInputField = ({
  fields,
  addFieldLabel,
  numberOfFields,
  setNumberOfFields,
  removeFieldLabel,
  minNumberOfFields,
}) => {
  return (
    <TableContainer>
      <TableWrapper>
        <Table>
          <thead>
            <TableHeaderRow>
              {fields.map(({label, tooltipContent}, index) => (
                <TableHeaderCell key={index}>
                  <InlineContainer>
                    {label}
                    {tooltipContent && (
                      <InfoTooltip id={'tooltip-' + index} content={tooltipContent} />
                    )}
                  </InlineContainer>
                </TableHeaderCell>
              ))}
            </TableHeaderRow>
          </thead>
          <TableBody>
            {Array.from({length: numberOfFields}, (_, i) => (
              <TableRow key={i}>
                {fields.map(({label, inputName}) => (
                  <TableCell key={`${inputName}-${i + 1}`}>
                    <InputField
                      name={`${inputName}-${i + 1}`}
                      label={`${label} ${i + 1}`}
                      type="text"
                      required
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
      <ButtonsRowContainer>
        <OptionsButton
          onClick={() => {
            setNumberOfFields(prev => prev + 1);
          }}
        >
          {addFieldLabel}
        </OptionsButton>
        <RedButton
          onClick={() => {
            setNumberOfFields(prev => prev - 1);
          }}
          disabled={numberOfFields <= minNumberOfFields}
        >
          {removeFieldLabel}
        </RedButton>
      </ButtonsRowContainer>
    </TableContainer>
  );
};
