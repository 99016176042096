import {collection, doc, getDocs, query, setDoc, updateDoc, where} from 'firebase/firestore';
import {firestore} from 'configuration/firebase/Firebase';

export const getUsersTeams = async uid => {
  try {
    const q = query(collection(firestore, 'teams'), where('users', 'array-contains-any', [uid]));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length === 0) {
      throw new Error('No team found.');
    }
    return querySnapshot.docs;
  } catch (error) {
    console.error(error);
  }
};

export const getCurrentTeam = async uid => {
  try {
    if (localStorage.getItem('selectedTeam')) {
      return localStorage.getItem('selectedTeam');
    } else {
      const teams = await getUsersTeams(uid);
      const defaultTeam = teams[0];
      localStorage.setItem('selectedTeam', defaultTeam.id);
      return defaultTeam.id;
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateApiKey = async (uid, apikey) => {
  try {
    const team = await getCurrentTeam(uid);
    const docRef = doc(firestore, 'teams', team);
    await updateDoc(docRef, {
      key: apikey,
    });
  } catch (error) {
    console.error(error);
    throw new Error('Unable to store API Key, please try again.');
  }
};

export const createNewTeamWithUser = async uid => {
  try {
    const teamCollectionReference = doc(collection(firestore, 'teams'));
    setDoc(teamCollectionReference, {
      users: [uid],
    });
  } catch (error) {
    console.error(error);
    throw new Error('Unable to create new with user id: ' + uid);
  }
};
