import React from 'react';
import tw from 'twin.macro';
import Pricing from 'components/pricing/ThreePlans.js';
import Header from 'components/headers/light';
import {PageContainer} from '../components/items/Layouts';

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`uppercase text-primary-500`;

  return (
    <PageContainer>
      <Header />
      <Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            <HighlightedText>1 Month </HighlightedText>FREE TRIAL
          </>
        }
        plans={[
          {
            name: 'Personal',
            price: '£99.99',
            duration: 'Ideal For Small Businesses With A Few Workflows',
            mainFeature: 'Included',
            features: [
              'Unlimited Email Variations',
              'Test Up To 15 Workflows',
              'Chat + Email + Phone Support',
              'Unlimited Users',
              'Up To 5 Members Of Your Team',
            ],
          },
          {
            name: 'Professional',
            price: '£149.99',
            duration: 'Ideal For Medium Businesses With Many Flows',
            mainFeature: 'Included',
            features: [
              'Unlimited Email Variations',
              'Test Up To 50 Workflows',
              'Chat + Email + Phone Support',
              'Unlimited Users',
              'Up To 10 Members Of Your Team',
            ],
            featured: true,
          },
          {
            name: 'Business',
            price: 'Contact Us',
            duration: 'Ideal For Large Businesses With Many Flows',
            mainFeature: 'Included',
            features: [
              'Unlimited Email Variations',
              'Unlimited Workflows',
              'Chat + Email + Phone Support',
              'Unlimited Users',
              'Unlimited Members Of Your Team',
            ],
          },
        ]}
      />
    </PageContainer>
  );
};
