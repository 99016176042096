import tw from 'twin.macro';

export const TableContainer = tw.div`mt-8`;
export const TableWrapper = tw.div`overflow-x-auto`;
export const Table = tw.table`min-w-full divide-y divide-gray-300 py-8`;
export const TableHeaderRow = tw.tr``;
export const TableHeaderCell = tw.th`py-3 text-left text-sm font-semibold text-gray-900`;
export const TableBody = tw.tbody`divide-y divide-gray-200`;
export const TableRow = tw.tr``;
export const TableCell = tw.td`whitespace-nowrap py-4 text-sm font-medium text-gray-900 sm:pl-0`;
