import tw from 'twin.macro';
import React from 'react';

const StatCardContainer = tw.dl`mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-4`;
const StatCard = tw.div`flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8`;
const StatName = tw.dt`text-sm font-medium leading-6 text-gray-500`;
const StatChange = tw.dd`text-xs font-medium`;
const StatValue = tw.dd`w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900`;

const StatChangePositive = tw(StatChange)`text-gray-700`;
const StatChangeNegative = tw(StatChange)`text-red-600`;

export default function StatCards({stats}) {
  return (
    <StatCardContainer>
      {stats.map(stat => (
        <StatCard key={stat.name}>
          <StatName>{stat.name}</StatName>
          {/*{stat.changeType === 'negative' ? (*/}
          {/*  <StatChangeNegative>{stat.change}</StatChangeNegative>*/}
          {/*) : (*/}
          {/*  <StatChangePositive>{stat.change}</StatChangePositive>*/}
          {/*)}*/}
          <StatValue>{stat.value}</StatValue>
        </StatCard>
      ))}
    </StatCardContainer>
  );
}
