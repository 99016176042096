import React, {useEffect, useState} from 'react';
import {PageContainer} from 'components/items/Layouts';
import tw from 'twin.macro';
import styled from 'styled-components';
import illustration from 'assets/images/stats-illustration.svg';
import googleIconImageSrc from 'assets/images/google-icon.png';
import {ReactComponent as LoginIcon} from 'feather-icons/dist/icons/log-in.svg';
import {IsLoggedIn, signInWithEmail, SignInWithGoogle} from 'helpers/api/auth/auth';
import {useNavigate} from 'react-router-dom';
import {LoadingContainer, PageLoading} from 'components/items/Loading';
import {getAdditionalUserInfo} from 'firebase/auth';
import Header from '../components/headers/light';

const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1 bg-primary-900`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-semibold text-secondary-text-dark`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const CenterContainer = tw.div`flex justify-center`;
const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;
const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full bg-contain bg-center bg-no-repeat`}
`;

export default ({
  illustrationImageSrc = illustration,
  headingText = 'Sign In',
  submitButtonText = 'Sign In',
  SubmitButtonIcon = LoginIcon,
}) => {
  const navigate = useNavigate();
  const [user, loading] = IsLoggedIn();
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
    setPageLoading(false);
  }, [loading, user, navigate]);

  const handleSignInWithGoogle = () => {
    setPageLoading(true);
    SignInWithGoogle()
      .then(r => {
        return {userCredential: r, additionalUserInfo: getAdditionalUserInfo(r)};
      })
      .then(r => {
        if (r.additionalUserInfo.isNewUser) {
          r.userCredential.user.delete().then(r => {
            alert('Please sign up first');
            navigate('/signup');
          });
        } else {
          navigate('/dashboard');
        }
        setPageLoading(false);
      });
  };

  const handleSignInWithEmailPwd = e => {
    setPageLoading(true);

    e.preventDefault();
    const data = new FormData(e.target);
    const email = data.get('email');
    const password = data.get('password');

    signInWithEmail(email, password)
      .then(r => {
        navigate('/dashboard');
      })
      .catch(e => {
        setPageLoading(false);
        alert('Email and password combination does not exist');
      });
  };

  return pageLoading ? (
    <PageContainer>
      <Header />
      <LoadingContainer>
        <PageLoading />
      </LoadingContainer>
    </PageContainer>
  ) : (
    <PageContainer>
      <Header />
      <CenterContainer>
        <Content id={'content'}>
          <MainContainer>
            <MainContent>
              <Heading>{headingText}</Heading>
              <FormContainer>
                <SocialButtonsContainer>
                  <SocialButton
                    onClick={() => {
                      handleSignInWithGoogle();
                    }}
                  >
                    <span className="iconContainer">
                      <img src={googleIconImageSrc} className="icon" alt="" />
                    </span>
                    <span className="text">{'Sign In With Google'}</span>
                  </SocialButton>
                </SocialButtonsContainer>
                <DividerTextContainer>
                  <DividerText>or sign in with your email</DividerText>
                </DividerTextContainer>
                <Form onSubmit={handleSignInWithEmailPwd}>
                  <Input type="email" placeholder="Email" name={'email'} />
                  <Input type="password" placeholder="Password" name={'password'} />
                  <SubmitButton type="submit">
                    <SubmitButtonIcon className="icon" />
                    <span className="text">{submitButtonText}</span>
                  </SubmitButton>
                </Form>
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustrationImageSrc} />
          </IllustrationContainer>
        </Content>
      </CenterContainer>
    </PageContainer>
  );
};
