export class BinaryMetric {
  constructor(successCount, failureCount, experimentId, variantId) {
    this._successCount = successCount;
    this._failureCount = failureCount;
    this._experimentId = experimentId;
    this._variantId = variantId;
  }
  get successCount() {
    return this._successCount;
  }

  get failureCount() {
    return this._failureCount;
  }
  get experimentId() {
    return this._experimentId;
  }
  get variantId() {
    return this._variantId;
  }

  getSuccessRate() {
    return this._successCount / (this._successCount + this._failureCount);
  }

  getTotalSent() {
    return this._successCount + this._failureCount;
  }
}

export class DailyBinaryMetric extends BinaryMetric {
  constructor(successCount, failureCount, experimentId, variantId, date) {
    super(successCount, failureCount, experimentId, variantId);
    this._date = date;
  }

  get date() {
    return this._date;
  }
}
