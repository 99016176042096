import tw from 'twin.macro';

export const SectionHeading = tw.h2`text-4xl sm:text-5xl font-black tracking-wide text-center`;
export const SmallSectionHeading = tw(SectionHeading)`text-lg`;
export const DashboardSectionHeading = tw.h2`md:text-3xl text-2xl tracking-wide text-secondary-text-dark`;
export const DashboardHeading = tw.h1`text-xl sm:text-3xl lg:text-4xl xl:text-5xl text-secondary-text-dark max-w-screen-lg flex capitalize flex-wrap`;
export const HighlightedDashboardHeading = tw(DashboardHeading)`text-primary-500`;
export const Subheading = tw.h5`font-bold text-primary-500`;
export const MediumHeading = tw.h3`text-lg`;
export const HighlightedText = tw.span`text-primary-500`;
export const BoldText = tw.span`font-bold`;

export const LogoText = tw.span`font-semibold tracking-wide items-center text-2xl!`;
