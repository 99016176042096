import tw from 'twin.macro';

export default function Radio({options, value, setValue}) {
  const Main = tw.main`grid w-1/12 place-items-center `;
  const Container = tw.div`grid grid-cols-2 rounded-xl bg-gray-100 p-2 w-full h-full`;
  const RadioInput = tw.input`hidden`;
  const Label = tw.label`block cursor-pointer select-none rounded-xl p-1 text-center peer-hover:bg-primary-lightest peer-checked:bg-primary-400 peer-checked:font-bold peer-checked:text-white`;

  return (
    <Main>
      <Container>
        {options.map((curr, ind) => {
          return (
            <div className={'peer'}>
              <RadioInput
                type="radio"
                name="option"
                id={ind}
                value={curr}
                defaultChecked={value === curr}
                className={'peer'}
                hidden
              />
              <Label
                htmlFor={ind}
                onClick={() => {
                  setValue(curr);
                }}
              >
                {curr}
              </Label>
            </div>
          );
        })}
      </Container>
    </Main>
  );
}
