import React, {createContext, useState} from 'react';
import Joyride, {ACTIONS} from 'react-joyride';
import {useNavigate} from 'react-router-dom';
import {HighlightedText, LogoText, SmallSectionHeading} from '../../components/items/Headings';
import {Container, ContentWithSlightVerticalPadding} from '../../components/items/Layouts';
import {HtmlAnchor} from '../../components/items/Typography';

const TourContext = createContext();
const ContentContainer = (heading, body) => {
  return (
    <Container>
      <SmallSectionHeading>{heading}</SmallSectionHeading>
      <ContentWithSlightVerticalPadding>{body}</ContentWithSlightVerticalPadding>
    </Container>
  );
};

const steps = [
  {
    content: ContentContainer(
      <LogoText>
        Mail<HighlightedText>Bandit</HighlightedText>
      </LogoText>,
      "Let's start by giving you a quick tour - we'll have you up and running in no time!\n"
    ),
    placement: 'center',
    target: 'body',
    pathname: '/dashboard',
  },
  {
    content: ContentContainer(
      'Dashboard',
      "Here you'll be able to see all your running experiments and check on their progress once they have been created..."
    ),
    target: '#dashboard-body',
    pathname: '/dashboard',
  },
  {
    target: '#create-experiment',
    content: ContentContainer(
      'Create A New Experiment',
      'Clicking here will take you to create a new experiment.'
    ),
    pathname: '/dashboard',
    placement: 'bottom',
  },
  {
    target: '#create-experiment-body',
    content: ContentContainer(
      'Create Experiment Page',
      'To create an experiment, we only need a few things from you.'
    ),
    pathname: '/create-experiment',
  },
  {
    target: '#create-experiment-name',
    content: ContentContainer(
      'Experiment Name',
      'Give your experiment a name. This name will show up on the dashboard.'
    ),
    pathname: '/create-experiment',
  },
  {
    target: '#create-experiment-metric',
    content: ContentContainer(
      'Choose A Metric',
      'Decide which metric you care the most about optimizing for.'
    ),
    pathname: '/create-experiment',
  },
  {
    target: '#create-experiment-variants',
    content: ContentContainer(
      'Template IDs',
      "Provide the Template ID's as well as the Subject Line and Preview Headers you would like to test."
    ),
    pathname: '/create-experiment',
    placement: 'bottom',
  },
  {
    target: '#create-experiment-button',
    content: ContentContainer(
      'Get Going!',
      "Click here to start the experiment. On the main page, you'll see your experiment running."
    ),
    pathname: '/create-experiment',
  },
  {
    target: '#header-configuration',
    content: ContentContainer(
      'API Key',
      "Don't forget to set your API key in the configuration page. This is essential in allowing us to send emails."
    ),
    pathname: '/create-experiment',
  },
  {
    target: '#config-body',
    content: ContentContainer(
      'API Key',
      <p>
        Start your journey by entering your Klaviyo Private API key here. This only needs to be done
        once.{' '}
        <HtmlAnchor
          href={'https://help.klaviyo.com/hc/en-us/articles/115005062267'}
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here to find out how.
        </HtmlAnchor>
      </p>
    ),
    pathname: '/config',
  },
];

const TourProvider = ({children}) => {
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const navigate = useNavigate();

  const handleJoyrideCallback = async data => {
    const {status, index, type, lifecycle, action} = data;
    let nextStep = false;
    let nextStepIndex = false;

    if (status === 'skipped') {
      setRun(false);
    } else if (lifecycle === 'complete') {
      if (action === ACTIONS.NEXT) {
        nextStepIndex = index + 1;
        nextStep = steps[nextStepIndex];
      } else if (action === ACTIONS.PREV) {
        nextStepIndex = index - 1;
        nextStep = steps[nextStepIndex];
      }

      if (nextStep) {
        if (nextStep.pathname !== window.location.pathname) {
          navigate(nextStep.pathname);
        }
        setStepIndex(nextStepIndex);
      } else {
        setRun(false);
      }
    }
  };

  return (
    <TourContext.Provider value={{run, stepIndex, setRun, setStepIndex}}>
      {run && (
        <Joyride
          steps={steps}
          run={run}
          stepIndex={stepIndex}
          continuous={true}
          showProgress={true}
          showSkipButton={true}
          callback={handleJoyrideCallback}
          disableOverlayClose={true}
          scrollOffset={80}
        />
      )}
      {children}
    </TourContext.Provider>
  );
};

export {TourContext, TourProvider};
