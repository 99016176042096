import React from 'react';
import tw from 'twin.macro';

const HiddenOnSmall = tw.div`sm:hidden`;
const Label = tw.label`sr-only`;
const Select = tw.select`block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500`;
const Container = tw.div``;
const BlockOnSmall = tw.div`hidden sm:block`;
const Nav = tw.nav`flex space-x-4`;
const CurrentLink = tw.a`rounded-md px-3 py-2 text-sm font-medium bg-gray-200 text-gray-800`;
const NonCurrentLink = tw.a`rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:text-gray-800`;

export default function Tabs({tabs, currentTab, setCurrentTab}) {
  return (
    <Container>
      <HiddenOnSmall>
        <Label htmlFor="tabs">Select a tab</Label>
        <Select
          id="tabs"
          name="tabs"
          defaultValue={tabs.find(tab => tab.name === currentTab.name).name}
          onChange={e => {
            setCurrentTab(tabs.find(tab => tab.name === e.target.value));
          }}
        >
          {tabs.map(tab => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </Select>
      </HiddenOnSmall>

      <BlockOnSmall>
        <Nav aria-label="Tabs">
          {tabs.map(tab =>
            tab.name === currentTab.name ? (
              <CurrentLink key={tab.name} aria-current="page">
                {tab.name}
              </CurrentLink>
            ) : (
              <NonCurrentLink
                key={tab.name}
                onClick={() => {
                  setCurrentTab(tab);
                }}
              >
                {tab.name}
              </NonCurrentLink>
            )
          )}
        </Nav>
      </BlockOnSmall>
    </Container>
  );
}
