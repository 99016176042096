import React, {useEffect, useState} from 'react';
import Header from 'components/headers/light';
import {ContentWithPaddingXl, PageContainer} from 'components/items/Layouts';
import tw from 'twin.macro';
import {DashboardHeading, HighlightedText, Subheading} from 'components/items/Headings';
import {OptionsButton} from 'components/items/Buttons';
import {useNavigate} from 'react-router-dom';
import {LoadingContainer, PageLoading} from 'components/items/Loading';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {IsLoggedIn} from 'helpers/api/auth/auth';
import {InputField} from 'components/forms/InputField';
import {getCurrentTeam, updateApiKey} from 'helpers/api/firestore/Teams';
import {SectionDescription} from '../components/items/Typography';
import {Modal} from '../components/items/Modals';

export default () => {
  const Form = tw.form`ring-1 ring-gray-500 rounded-md h-full p-5`;
  const Column = tw.div`w-full flex flex-row  pt-5 px-5`;
  const CreateButtonContainer = tw.div`w-full flex justify-center text-primary-500 pt-10`;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [APIKeyModal, setAPIKeyModal] = useState(false);
  const [user, userLoading] = IsLoggedIn();
  const [selectedTeam, setSelectedTeam] = useState(
    localStorage.getItem('selectedTeam') || undefined
  );
  const SemiBoldContainer = tw.div`font-semibold w-full`;

  const validationSchema = Yup.object().shape({
    apiKey: Yup.string().required('API Key required.'),
  });

  useEffect(() => {
    getCurrentTeam(user.uid).then(team => {
      setSelectedTeam(team);
      setLoading(false);
    });
  }, [userLoading]);

  const handleSubmit = e => {
    setLoading(true);
    e.preventDefault();

    const data = new FormData(e.target);
    const apiKey = data.get('apiKey');
    updateApiKey(user.uid, apiKey).then(() => {
      setLoading(false);
      setAPIKeyModal(true);
    });
  };

  if (loading) {
    return (
      <LoadingContainer>
        <PageLoading />
      </LoadingContainer>
    );
  }
  return (
    <PageContainer>
      <Header isLoggedIn={true} />
      <ContentWithPaddingXl id={'config-body'}>
        <Modal
          isOpen={APIKeyModal}
          modalHeading={'API Key Set'}
          modalBody={<Subheading>Your API Key has been set, ready to go!</Subheading>}
          modalButtons={[
            {
              text: 'Continue',
              action: () => {
                setAPIKeyModal(false);
                navigate('/dashboard');
              },
              buttonTag: OptionsButton,
            },
          ]}
        />

        <DashboardHeading>
          {' '}
          Set&nbsp; <HighlightedText>Configuration</HighlightedText> &nbsp;
        </DashboardHeading>
        <SectionDescription>Team:{selectedTeam}</SectionDescription>
        <Formik
          initialValues={{apiKey: ''}}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({touched, isValid}) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Column>
                  <SemiBoldContainer>
                    <InputField
                      name={'apiKey'}
                      id={'apiKey'}
                      label={'API Key'}
                      tooltipContent={
                        '<div>Create a private API key on Klaviyo. On Klaviyo, navigate to the following:</div><div>Settings > API keys > Create Private API Key > Full Access Key</div>'
                      }
                    />
                  </SemiBoldContainer>
                </Column>
                <CreateButtonContainer>
                  <OptionsButton
                    type={'submit'}
                    disabled={!isValid || Object.keys(touched).length === 0}
                  >
                    Submit
                  </OptionsButton>
                </CreateButtonContainer>
              </Form>
            );
          }}
        </Formik>
      </ContentWithPaddingXl>
    </PageContainer>
  );
};
