export class ContinuousMetric {
  constructor(mean, variance, count, experimentId, variantId) {
    this._variance = variance;
    this._mean = mean;
    this._count = count;
    this._experimentId = experimentId;
    this._variantId = variantId;
  }
  get variance() {
    return this._variance;
  }

  get mean() {
    return this._mean;
  }
  get count() {
    return this._count;
  }
  get experimentId() {
    return this._experimentId;
  }
  get variantId() {
    return this._variantId;
  }

  getTotalSuccess() {
    return this._count !== 0 ? this._count * this._mean : 0;
  }
}

export class DailyContinuousMetric extends ContinuousMetric {
  constructor(mean, variance, count, experimentId, variantId, date) {
    super(mean, variance, count, experimentId, variantId);
    this._date = date;
  }

  get date() {
    return this._date;
  }
}
