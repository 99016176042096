import React from 'react';
import {Navigate} from 'react-router-dom';
import {IsLoggedIn} from 'helpers/api/auth/auth';
import {LoadingContainer, PageLoading} from 'components/items/Loading';

export const PrivateRoute = ({component: Component}) => {
  const [user, loading] = IsLoggedIn();
  if (loading) {
    return (
      <LoadingContainer>
        <PageLoading />
      </LoadingContainer>
    );
  }
  return user ? <Component /> : <Navigate to="/login" />;
};
