import {doc, getDoc, setDoc} from 'firebase/firestore';
import {firestore} from 'configuration/firebase/Firebase';
import {Variant} from '../../objects/Variant';

export const setVariantFields = async variant => {
  try {
    const variantDocRef = doc(
      firestore,
      'experiments',
      variant.experimentId,
      'variants',
      variant.variantId
    );
    await setDoc(variantDocRef, variant.toDict());
  } catch (error) {
    console.error(error);
    throw new Error('Unable to add variant fields, please try again!');
  }
};

export const getVariantFields = async (experimentId, variantId) => {
  try {
    const variantDocRef = doc(firestore, 'experiments', experimentId, 'variants', variantId);
    const variantSnap = await getDoc(variantDocRef);
    if (variantSnap.exists()) {
      const variant = variantSnap.data();
      return new Variant(
        experimentId,
        variantId,
        variant['subject_line'],
        variant['preview_header'],
        variant['attributes']
      );
    } else {
      throw new Error('No such variant!');
    }
  } catch (error) {
    console.error(error);
    throw new Error('Unable to get variant fields, please try again!');
  }
};
