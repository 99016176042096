import React from 'react';
import tw from 'twin.macro';
import AnimationRevealPage from 'helpers/animations/AnimationRevealPage.js';
import Hero from 'components/multicolumn/TwoColumnWithInput.js';
import Features from 'components/multicolumn/ThreeColWithSideImage.js';
import FeatureWithSteps from 'components/multicolumn/TwoColWithSteps.js';
import GetStarted from 'components/demo/GetStarted';
import getStartedLaptopImg from 'assets/images/get-started-laptop.png';
import aiIcon from 'assets/images/ai-icon.png';
import linkIcon from 'assets/images/link.png';
import trendIcon from 'assets/images/trend.png';
import layerIcon from 'assets/images/layer.png';
import headphonesIcon from 'assets/images/headphones.png';
import realtimeIcon from 'assets/images/real-time.png';
import Header from 'components/headers/light';
import {PageContainer} from '../components/items/Layouts';
import LogoCloud from '../components/multicolumn/LogoCloud';

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;
  const featureCards = [
    {
      imageSrc: aiIcon,
      title: 'Keep Your Forgotten Flows Refreshed',
      description: '',
    },
    {
      imageSrc: linkIcon,
      title: 'Easy Integration With Your Existing Platform',
      description: '',
    },
    {
      imageSrc: headphonesIcon,
      title: '24/7 Support Tailored To Your Needs',
      description: '',
    },
    {
      imageSrc: trendIcon,
      title: 'Increased Clicks And Revenue',
      description: '',
    },
    {
      imageSrc: layerIcon,
      title: 'Minimize Your A/B Testing Losses',
      description: '',
    },
    {
      imageSrc: realtimeIcon,
      title: 'An AI That Adapts To Your Customers In Realtime',
      description: '',
    },
  ];

  return (
    <PageContainer>
      <Header />
      <AnimationRevealPage>
        <Hero />
        <LogoCloud />

        <Features
          subheading={<></>}
          heading={<>Improve Your Conversions & Clicks Using AI</>}
          description={''}
          cards={featureCards}
        />

        <FeatureWithSteps
          subheading={<Subheading>STEPS</Subheading>}
          heading={
            <>
              <HighlightedText>Get Started</HighlightedText>
            </>
          }
          textOnLeft={false}
          imageSrc={getStartedLaptopImg}
          imageDecoratorBlob={true}
          decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
        />
        {/*<Testimonial*/}
        {/*  subheading={<Subheading>Testimonials</Subheading>}*/}
        {/*  description={''}*/}
        {/*  heading={*/}
        {/*    <>*/}
        {/*      Our Clients <HighlightedText>Love Us.</HighlightedText>*/}
        {/*    </>*/}
        {/*  }*/}
        {/*  testimonials={[*/}
        {/*    {*/}
        {/*      stars: 5,*/}
        {/*      profileImageSrc:*/}
        {/*        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80',*/}
        {/*      heading: 'Game-Changer',*/}
        {/*      quote:*/}
        {/*        'MailBandit has been a game-changer for our email marketing strategy. The AI-powered AB testing has saved us countless hours and provided us with invaluable insights. We were able to easily swap in and swap out variations, allowing us to quickly experiment and optimize our campaigns. The interpretable AI decisions gave us confidence in the recommendations, and the personalized content recommendations resulted in higher engagement and conversions. The real-time analytics and expert support have been instrumental in our success. I highly recommend MailBandit to any marketer looking to take their email campaigns to the next level.',*/}
        {/*      customerName: 'Sarah J.',*/}
        {/*      customerTitle: 'Marketing Manager',*/}
        {/*    },*/}
        {/*    {*/}
        {/*      stars: 5,*/}
        {/*      profileImageSrc:*/}
        {/*        'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80',*/}
        {/*      heading: 'Indispensable!',*/}
        {/*      quote:*/}
        {/*        "Since integrating MailBandit into our email marketing workflow, we have seen a significant boost in our conversion rates. The easy variation swapping feature allowed us to test different elements of our emails effortlessly, helping us find the winning combinations that resonate with our audience. The AI-powered AB testing took away the guesswork and allowed us to focus on other aspects of our business. The real-time analytics and insights helped us make data-driven decisions and optimize our campaigns on the fly. MailBandit has become an indispensable tool in our marketing arsenal, and we couldn't be happier with the results. Thank you, MailBandit team!",*/}
        {/*      customerName: 'John M.',*/}
        {/*      customerTitle: 'E-commerce Entrepreneur',*/}
        {/*    },*/}
        {/*  ]}*/}
        {/*/>*/}
        <GetStarted />
      </AnimationRevealPage>
    </PageContainer>
  );
};
