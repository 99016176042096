import {metricsInfoServiceURL} from '../../../configuration/firebase/Firebase';
import {getCurrentTeam} from '../firestore/Teams';

export async function fetchAvailableMetrics(uid) {
  const team = await getCurrentTeam(uid);
  const body = {team_id: team};

  try {
    const response = await fetch(metricsInfoServiceURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error making POST request:', error);
    throw error;
  }
}
