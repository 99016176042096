export class Experiment {
  constructor(name, activeVariants, isRunning, startDate, team, id, metricId, metricName) {
    this._name = name;
    this._activeVariants = activeVariants;
    this._isRunning = isRunning;
    this._startDate = startDate;
    this._team = team;
    this._id = id;
    this._metricId = metricId;
    this._metricName = metricName;
  }

  get name() {
    return this._name;
  }
  get activeVariants() {
    return this._activeVariants;
  }

  get isRunning() {
    return this._isRunning;
  }

  get startDate() {
    return this._startDate;
  }

  get team() {
    return this._team;
  }

  get id() {
    return this._id;
  }
  get metricId() {
    return this._metricId;
  }
  get metricName() {
    return this._metricName;
  }

  numberOfDaysRan() {
    const currentDate = new Date();
    const differenceInTime = currentDate.getTime() - this._startDate.getTime();
    return parseInt(differenceInTime / (1000 * 3600 * 24));
  }

  stopExperiment() {
    this._isRunning = false;
  }
}
