import {useAuthState} from 'react-firebase-hooks/auth';
import {
  connectAuthEmulator,
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import {firebaseApp} from 'configuration/firebase/Firebase';

const auth = getAuth(firebaseApp);

if (process.env.NODE_ENV !== 'production') {
  connectAuthEmulator(auth, 'http://127.0.0.1:9099');
}

export const SignInWithGoogle = async () => {
  try {
    return await signInWithPopup(auth, new GoogleAuthProvider());
  } catch (error) {
    console.error(error);
  }
};

export const signInWithEmail = async (email, pwd) => {
  try {
    return await signInWithEmailAndPassword(auth, email, pwd);
  } catch (e) {
    console.error(e);
    throw new Error('Email and password does not match, please try again.');
  }
};

export const signUpWithEmailPwd = async (email, pwd) => {
  try {
    return await createUserWithEmailAndPassword(auth, email, pwd);
  } catch (error) {
    console.error(error);
  }
};

export const logout = () => signOut(auth);

export const IsLoggedIn = () => useAuthState(auth);
