import React from 'react';
import tw from 'twin.macro';
import introducing_mailbandit from 'assets/videos/introducing_mailbandit.mp4';
import Header from 'components/headers/light';
import {PageContainer} from '../components/items/Layouts';

export default () => {
  const VideoBox = tw.div`md:w-3/4 m-auto border-4 border-primary-500 rounded-md mt-5`;

  return (
    <PageContainer>
      <Header />
      <VideoBox>
        <video controls autoPlay={true}>
          <source src={introducing_mailbandit} type="video/mp4" />
        </video>
      </VideoBox>
    </PageContainer>
  );
};
