import React from 'react';
import tw from 'twin.macro';
import {useField} from 'formik';
import {ValidationError} from '../items/Typography';
import InfoTooltip from '../../helpers/tour/InfoTooltip';

export const Input = tw.input`border-2 rounded-md py-1 px-2 my-1`;
export const InputContainer = tw.div`flex flex-col w-11/12`;

export const InputField = props => {
  const [field, meta, helpers] = useField(props.name);
  const InlineContainer = tw.div`inline-flex`;

  return (
    <InputContainer>
      <label htmlFor={props.id}>
        <InlineContainer>
          {props.label}
          {props.tooltipContent && (
            <InfoTooltip id={'tooltip-' + props.id} content={props.tooltipContent} />
          )}
        </InlineContainer>
      </label>
      <Input
        {...field}
        {...props}
        id={props.id}
        name={props.name}
        type={props.type}
        onChange={e => {
          if (props.onChange) {
            props.onChange(e);
          }
          field.onChange(e);
        }}
      />
      {meta.error && meta.touched && <ValidationError>{meta.error}</ValidationError>}
    </InputContainer>
  );
};
