import React from 'react';
import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import {IoIosInformationCircleOutline} from 'react-icons/io';
import tw from 'twin.macro';

const InfoTooltip = ({id, content}) => {
  const TooltipContainer = tw.div`text-gray-600 font-normal`;
  return (
    <>
      <TooltipContainer>
        <Tooltip id={id} />
        <a data-tooltip-id={id} data-tooltip-html={content}>
          <IoIosInformationCircleOutline />
        </a>
      </TooltipContainer>
    </>
  );
};

export default InfoTooltip;
