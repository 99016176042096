import React, {useContext, useEffect, useState} from 'react';
import Header from 'components/headers/light';
import ExperimentTable from 'components/experiment/ExperimentTable';
import {ContentWithPaddingXl, PageContainer} from 'components/items/Layouts';
import {DashboardHeading, HighlightedText} from 'components/items/Headings';
import {OptionsButton} from 'components/items/Buttons';
import tw from 'twin.macro';
import {useNavigate} from 'react-router-dom';
import {IsLoggedIn} from 'helpers/api/auth/auth';
import {LoadingContainer, PageLoading} from 'components/items/Loading';
import {getCurrentTeam, getUsersTeams} from '../helpers/api/firestore/Teams';
import {getActiveExperimentsByTeam} from '../helpers/api/firestore/Experiments';
import {TourContext} from '../helpers/tour/TourContext';
import SimpleCustomSelectMenu from '../components/forms/SimpleCustomSelectMenu';
import InfoTooltip from '../helpers/tour/InfoTooltip';

export default () => {
  const CreateButtonContainer = tw.button`w-full flex justify-center text-primary-500`;
  const StartTourButton = tw.button`underline hocus:text-primary-500`;
  const InlineContainer = tw.div`inline-flex `;

  const navigate = useNavigate();
  const [user] = IsLoggedIn();
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(
    localStorage.getItem('selectedTeam') || undefined
  );
  const [loading, setLoading] = useState(true);
  const [experiments, setExperiments] = useState([]);
  const {run, stepIndex, setRun, setStepIndex} = useContext(TourContext);
  const [continueTour, setContinueTour] = useState(false);

  useEffect(() => {
    // gets teams users are a part of and set it for dropdown
    // defaults to first team if one is not chosen
    getCurrentTeam(user.uid).then(team => {
      setSelectedTeam(team);
    });
    getUsersTeams(user.uid).then(teams => {
      setTeams(teams);
    });

    //If team chosen, gets experiments for team
    if (selectedTeam) {
      getActiveExperimentsByTeam(selectedTeam).then(experiments => {
        setExperiments(experiments);
        setLoading(false);
      });
    }
  }, [selectedTeam, user]);

  useEffect(() => {
    if (run && loading) {
      setRun(false);
      setContinueTour(true);
    }
    if (continueTour && !loading) {
      setContinueTour(false);
      setRun(true);
    }
  }, [loading]);

  const handleTeamChange = e => {
    setSelectedTeam(e.target.value);
    localStorage.setItem('selectedTeam', e.target.value);
  };

  if (loading) {
    return (
      <PageContainer>
        <Header isLoggedIn={true} />
        <LoadingContainer>
          <PageLoading />
        </LoadingContainer>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Header isLoggedIn={true} />
      <ContentWithPaddingXl id={'dashboard-body'}>
        {teams.length > 1 ? (
          <SimpleCustomSelectMenu
            htmlId={'team'}
            label={'Select Team'}
            value={selectedTeam}
            options={teams.map(team => team.id)}
            onChange={handleTeamChange}
          />
        ) : (
          <>
            <InlineContainer>
              Manage multiple Klaviyo accounts?
              <InfoTooltip
                id={'multiple-teams'}
                content={
                  'Please get in touch with us on hello@mailbandit.co.uk and we can provide you with a bespoke setup.'
                }
              />
            </InlineContainer>
          </>
        )}
        <DashboardHeading>
          You have&nbsp;
          <HighlightedText>{experiments.length}</HighlightedText> &nbsp;experiment
          {experiments.length > 1 ? 's' : ''} running
        </DashboardHeading>
        <ExperimentTable listOfExperiments={experiments} />
        <CreateButtonContainer>
          <OptionsButton onClick={() => navigate('/create-experiment')} id="create-experiment">
            Create New Experiment
          </OptionsButton>
        </CreateButtonContainer>

        <StartTourButton
          onClick={() => {
            setStepIndex(0);
            setRun(true);
          }}
        >
          Show me around
        </StartTourButton>
      </ContentWithPaddingXl>
    </PageContainer>
  );
};
