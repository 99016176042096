import React from 'react';
import tw from 'twin.macro';
import {Container} from 'components/items/Layouts';
import {useNavigate, createSearchParams} from 'react-router-dom';
import {BoldText, DashboardSectionHeading, HighlightedText} from 'components/items/Headings';
import {OptionsButton} from 'components/items/Buttons';

const Table = tw.table`flex flex-wrap my-10 space-y-5`;
const TableRow = tw.tr`flex flex-wrap border-primary-500 ring-1 ring-gray-500 w-full py-5 rounded-md`;
const ExperimentNameCell = tw.td`w-full md:w-3/12 justify-center flex flex-wrap self-center p-5`;
const ExperimentInformationCell = tw.td`w-full md:w-5/12 justify-center flex flex-wrap self-center`;
const ExperimentExploreCell = tw.td`w-full md:w-4/12 justify-center flex flex-wrap self-center px-5 md:px-0`;
const SubTableCell = tw.td`w-full flex flex-wrap px-5 py-1`;

export default ({listOfExperiments = []}) => {
  const navigate = useNavigate();
  return (
    <Container>
      <Table>
        {listOfExperiments.map(experiment => {
          return (
            <TableRow>
              <ExperimentNameCell>
                <DashboardSectionHeading>
                  <BoldText>
                    Experiment: <br />
                  </BoldText>
                  <HighlightedText>{experiment.name}</HighlightedText>
                </DashboardSectionHeading>
              </ExperimentNameCell>
              <ExperimentInformationCell>
                <table>
                  <tr>
                    <SubTableCell>
                      <BoldText>Experiment has been running for:</BoldText>
                      {experiment.numberOfDaysRan()} day(s)
                    </SubTableCell>
                  </tr>
                  <tr>
                    <SubTableCell>
                      <BoldText>Number of variants: </BoldText> &nbsp;
                        {experiment.activeVariants.length}
                    </SubTableCell>
                  </tr>
                </table>
              </ExperimentInformationCell>
              <ExperimentExploreCell>
                <OptionsButton
                  onClick={() =>
                    navigate({
                      pathname: '/experiment',
                      search: createSearchParams({
                        experiment: experiment.id,
                      }).toString(),
                    })
                  }
                >
                  View Performance
                </OptionsButton>
              </ExperimentExploreCell>
            </TableRow>
          );
        })}
      </Table>
    </Container>
  );
};
