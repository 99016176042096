// Modal.js
import React from 'react';
import tw from 'twin.macro';
import {Container} from './Layouts';
import {DashboardHeading} from './Headings';
import {SectionDescription} from './Typography';
import {OptionsButton} from './Buttons';
import {FaRegCopy} from 'react-icons/fa';

const Overlay = tw.div`fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center`;

const ModalContent = tw.div`w-1/2 bg-white rounded-lg shadow-xl `;
const ModalHeader = tw.div`mx-5 mt-5`;
const ModalBody = tw.div`p-5 `;
const ModalActions = tw.div`p-5 justify-evenly flex`;

export const Modal = ({
  isOpen,
  modalHeading = 'Some heading',
  modalBody = 'Please copy and paste the following into your Klaviyo webhook in order for the experiment to work more mroe more:',
  modalButtons = [
    {
      text: 'close',
      action: () => {
        console.log('close clicked');
      },
      buttonTag: OptionsButton,
    },
  ],
}) => {
  return (
    <Container style={{display: isOpen ? 'fixed' : 'none'}}>
      <Overlay>
        <ModalContent>
          <ModalHeader>
            <DashboardHeading>{modalHeading}</DashboardHeading>
          </ModalHeader>
          <ModalBody>{modalBody}</ModalBody>
          <ModalActions>
            {modalButtons.map(mb => {
              return <mb.buttonTag onClick={mb.action}>{mb.text}</mb.buttonTag>;
            })}
          </ModalActions>
        </ModalContent>
      </Overlay>
    </Container>
  );
};

export const ModalCopyLineItem = ({text, iconStates, index, setIconStates}) => {
  const ModaLineItem = tw.div`flex justify-between`;
  const CopyButton = tw(FaRegCopy)`text-secondary-text-main  hocus:text-gray-200`;
  const handleClick = index => {
    setIconStates(prevStates => {
      const newState = [...prevStates]; // Copy the previous state array
      newState[index] = !newState[index]; // Toggle the state of the clicked button
      return newState; // Update the state
    });
  };

  return (
    <ModaLineItem>
      <SectionDescription>{text}</SectionDescription>
      {iconStates[index] ? (
        <>
          <SectionDescription>copied!</SectionDescription>
        </>
      ) : (
        <CopyButton
          onClick={() => {
            navigator.clipboard.writeText(text).then(() => {
              handleClick(index); // Change the icon

              setTimeout(() => {
                handleClick(index); // Change back to the original icon
              }, 1000);
            });
          }}
        />
      )}
    </ModaLineItem>
  );
};
