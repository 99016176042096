import React from 'react';
import tw from 'twin.macro';
import {Container, ContentWithPaddingXl} from 'components/items/Layouts';

const PrimaryBackgroundContainer = tw.div`py-20 lg:py-20 ring-1 ring-gray-500 rounded-md relative `;
const Row = tw.div`px-8 max-w-screen-lg mx-auto flex items-center relative z-10 flex-col lg:flex-row text-center lg:text-left`;

const ColumnContainer = tw.div`lg:w-1/2 max-w-lg`;
const TextContainer = tw(ColumnContainer)``;
const Text = tw.h5`text-primary-500 text-2xl sm:text-3xl font-bold`;

const LinksContainer = tw(
  ColumnContainer
)`flex justify-center lg:justify-end mt-6 lg:mt-0 flex-col sm:flex-row`;

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(
  Link
)`bg-primary-500 text-gray-100 shadow-lg hocus:bg-primary-900 select-none`;
export default ({
  text = (
    <>
      Sign up today and start your free 30 day trial.
      <br />
      *No credit card details required.
    </>
  ),
  primaryLinkText = 'Sign Up',
  primaryLinkUrl = '/signup',
  pushDownFooter = true,
}) => {
  return (
    <Container css={pushDownFooter && tw`mb-20 lg:mb-24`}>
      <ContentWithPaddingXl>
        <PrimaryBackgroundContainer>
          <Row>
            <TextContainer>
              <Text>{text}</Text>
            </TextContainer>
            <LinksContainer>
              <PrimaryLink href={primaryLinkUrl}>{primaryLinkText}</PrimaryLink>
            </LinksContainer>
          </Row>
        </PrimaryBackgroundContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
