import React from 'react';
import tw from 'twin.macro';
import {HtmlAnchor} from '../items/Typography';

const Container = tw.div`py-10`;
const TableContainer = tw.div`mt-8 overflow-hidden rounded-lg shadow`;
const Table = tw.table`min-w-full divide-y divide-gray-200 bg-white`;
const TableHead = tw.thead`bg-gray-100`;
const TableRow = tw.tr`transition-colors hover:bg-primary-100`;
const TableHeader = tw.th`py-3 pl-4 pr-3 text-left text-sm font-semibold text-gray-700`;
const TableData = tw.td`px-6 py-4 whitespace-nowrap text-sm text-gray-700`;
const TableBody = tw.tbody`bg-white divide-y divide-gray-200`;

export default function TableStats({headers, stats}) {
  return (
    <Container>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map(header => (
                <TableHeader>{header}</TableHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stats.map(stat => (
              <TableRow key={stat.id}>
                <TableData>{stat.rank}</TableData>
                <TableData>{stat.variantName}</TableData>
                <TableData>{stat.metric}</TableData>
                <TableData>{stat.allocation}</TableData>
                <TableData>{stat.emailsSent}</TableData>
                <TableData>
                  <HtmlAnchor
                    href={'https://www.klaviyo.com/email-template-editor/' + stat.templateId}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Edit template
                  </HtmlAnchor>
                </TableData>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
