export class Variant {
  constructor(experimentId, variantId, subjectLine, previewHeader, attributes) {
    this._experimentId = experimentId;
    this._variantId = variantId;
    this._subjectLine = subjectLine;
    this._previewHeader = previewHeader;
    this._attributes = attributes;
  }

  get experimentId() {
    return this._experimentId;
  }

  get variantId() {
    return this._variantId;
  }

  get subjectLine() {
    return this._subjectLine;
  }

  get previewHeader() {
    return this._previewHeader;
  }

  get variantName() {
    return this._attributes['name'];
  }

  toDict() {
    return {
      subject_line: this._subjectLine,
      preview_header: this._previewHeader,
      attributes: this._attributes,
    };
  }
}
