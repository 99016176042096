import {Line} from 'react-chartjs-2';
import React from 'react';

export default ({dateLabelsOverTime, successOverTime, ylabel}) => {
  return (
    <Line
      data={{
        labels: dateLabelsOverTime.map(d => {
          return (
            d.getDate().toString().padStart(2, 0) +
            '/' +
            (d.getMonth() + 1).toString().padStart(2, 0) +
            '/' +
            d.getFullYear()
          );
        }),
        datasets: successOverTime,
      }}
      options={{
        responsive: true,
        spanGaps: true,
        plugins: {
          legend: {
            display: true,
            position: 'top',
            labels: {
              color: '#4A5568',
              font: {
                size: 14,
                weight: 'bold',
              },
            },
          },
          tooltip: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            titleFont: {size: 16, weight: 'bold'},
            bodyFont: {size: 14},
            cornerRadius: 4,
            xPadding: 10,
            yPadding: 10,
            displayColors: false,
          },
        },
        scales: {
          y: {
            min: 0,
            title: {
              display: true,
              text: ylabel,
              color: '#4A5568',
              font: {
                size: 16,
                weight: 'bold',
              },
            },
            ticks: {
              color: '#4A5568',
              font: {
                size: 14,
              },
              beginAtZero: true,
              max: 100,
            },
            grid: {
              color: 'rgba(74, 85, 104, 0.1)',
            },
          },
          x: {
            title: {
              display: true,
              text: 'Date',
              color: '#4A5568',
              font: {
                size: 16,
                weight: 'bold',
              },
            },
            ticks: {
              color: '#4A5568',
              font: {
                size: 14,
              },
            },
            grid: {
              color: 'rgba(74, 85, 104, 0.1)',
            },
          },
        },
      }}
    />
  );
};
