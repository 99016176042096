import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import {ReactComponent as SvgDecoratorBlob1} from 'assets/images/svg-decorator-blob-1.svg';
import DesignIllustration from 'assets/images/homepage_email_variations.jpg';
import {Link} from 'react-router-dom';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;
const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-secondary-text-dark leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg text-secondary-text-main`;
const HighlightedText = tw.span`text-primary-500`;

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0 pt-4 select-none`}

  button {
    ${tw`w-full right-0 top-0 bottom-0 bg-primary-500 text-white font-bold rounded-full py-6 flex items-center justify-center sm:w-64 sm:leading-none hover:bg-primary-900 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

export default () => {
  return (
    <>
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              A/B Test Your <HighlightedText>Forgotten Flows </HighlightedText> With Minimal Effort
              Using MailBandit's AI
            </Heading>
            <Paragraph>Start Your 30 Day Free Trial Today🚀</Paragraph>
            <Actions>
              <Link to={'/signup'}>
                <button>Sign Up</button>
              </Link>
            </Actions>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img src={DesignIllustration} alt="Design Illustration" />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
