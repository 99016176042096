import React from 'react';
import tw from 'twin.macro';
import {css} from 'styled-components/macro'; //eslint-disable-line
import Header from 'components/headers/light.js';
import ContactUsForm from 'components/forms/TwoColContactUsWithIllustrationFullForm.js';
import AnimationRevealPage from 'helpers/animations/AnimationRevealPage';

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <ContactUsForm />
    </AnimationRevealPage>
  );
};
