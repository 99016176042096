import tw from 'twin.macro';
import {Container} from '../items/Layouts';
import KlaviyoLogo from 'assets/images/klaviyo-primary-logo-charcoal-medium.png';
import {Link} from 'react-router-dom';

const InnerContainer = tw.div`mx-auto px-6 lg:px-8 max-w-screen-xl`;
const Heading = tw.h2`text-center text-lg font-semibold leading-8 text-secondary-text-dark`;
const GridContainer = tw.div`mx-auto grid max-w-lg grid-cols-5 gap-x-8 gap-y-10 my-10 sm:max-w-xl sm:grid-cols-5 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5`;
const Image = tw.img`col-span-2  w-full object-contain lg:col-span-1 w-128`;
const ClickableLink = tw(Link)`underline text-secondary-text-dark`;

export default () => {
  return (
    <Container>
      <InnerContainer>
        <Heading>Currently integrated with:</Heading>
        <GridContainer>
          <Image src={KlaviyoLogo} alt="Transistor" />
        </GridContainer>
        <ClickableLink to={'https://ilzt69c6hgg.typeform.com/to/MpHa9n30'}>
          Want to see us on your platform? Let us know!
        </ClickableLink>
      </InnerContainer>
    </Container>
  );
};
